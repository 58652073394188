import { Button } from "@/components";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useQueryGetParameterizationValues } from "@/service/administrative-area";
import { useQueryGetSellersWithNoSales } from "@/service/reports/sales/notifications";
import { useCompanyStore } from "@/store/company";
import { BellRing } from "lucide-react";
import { Link } from "react-router-dom";

export const Notifications = () => {
  const companySelected = useCompanyStore((store) => store.company);

  const { isSuccess: isSucessParameters, data: parameterizationValues } =
    useQueryGetParameterizationValues(companySelected.id, {});

  const { data, isLoading } = useQueryGetSellersWithNoSales(
    companySelected.id,
    parameterizationValues?.notifications_days_fired_users,
    {
      enabled: isSucessParameters,
    }
  );

  const notificationCount = isLoading || !data ? null : data.length;

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" size="icon" className="relative ml-6">
          <BellRing className="h-6 w-6" />

          {notificationCount > 0 && !isLoading && (
            <span className="absolute right-0 top-0 h-3 w-3 rounded-full bg-red-500" />
          )}
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end" className="w-80">
        {isLoading && (
          <DropdownMenuItem>Carregando notificações...</DropdownMenuItem>
        )}
        {!isLoading && data?.length > 0 && (
          <DropdownMenuItem className="flex flex-col items-start p-4">
            <div className="text-sm font-semibold">
              Existem {data.length} usuários potencialmente desligados da
              empresa
            </div>
            <div className="mt-2 flex gap-2">
              <Link to={"administrative/users?selectedTabs=noSales"}>
                <Button variant="outline" size="sm">
                  Visualizar
                </Button>
              </Link>
            </div>
          </DropdownMenuItem>
        )}
        {!isLoading && data?.length === 0 && (
          <DropdownMenuItem>Nenhuma notificação</DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
