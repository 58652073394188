import {
  Button,
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components"
import { subMonths } from "date-fns"
import { ChevronDown, ChevronsLeftIcon, ChevronsRightIcon } from "lucide-react"

export default function CalendarDatePickerRangeSelector({
  setDisplayedMonth,
  handleMonthChange,
  setSelectedRange,
  internalDate,
  months,
  allowPastDates,
  today,
  yearFrom,
  years,
  handleYearChange,
  numberOfMonths,
  monthFrom,
  yearTo
}) {
  return (
    <div className="flex items-center justify-between gap-4 max-sm:flex-col">
      <div className="ml-3 flex gap-2">
        <Button
          variant={"outline"}
          className="px-2"
          onClick={() => {
            setDisplayedMonth(internalDate.from)
          }}
        >
          <ChevronsLeftIcon
            strokeWidth={1.5}
            className="h-4 w-4 text-muted-foreground"
          />
        </Button>
        <Select
          onValueChange={(value) => {
            handleMonthChange(months.indexOf(value), "from")
            setSelectedRange(null)
          }}
          value={
            internalDate?.from
              ? months[internalDate.from.getMonth()]
              : undefined
          }
        >
          <SelectTrigger className="h-9 w-auto font-medium hover:bg-accent hover:text-accent-foreground focus:ring-0 focus:ring-offset-0">
            <SelectValue placeholder="Mês" />
          </SelectTrigger>
          <SelectContent>
            {months.map((month, idx) => (
              <SelectItem
                disabled={!allowPastDates ? month < today.getMonth() : false}
                key={idx}
                value={month}
              >
                {month}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Popover modal>
          <PopoverTrigger className="h-9 w-20">
            <Button
              type="button"
              variant="outline"
              className="w-20 justify-between"
            >
              <p>{yearFrom || "Ano"}</p>
              <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="w-24 p-0">
            <Command value={yearFrom ? yearFrom.toString() : undefined}>
              <CommandInput placeholder={"Ano"} />
              <CommandGroup className="max-h-64 w-24 overflow-auto">
                {years.map((year) => (
                  <CommandItem
                    key={year}
                    disabled={!allowPastDates && year < today.getFullYear()}
                    value={year.toString()}
                    onSelect={(value) => {
                      handleYearChange(Number(value), "from")
                      setSelectedRange(null)
                    }}
                    className="inset-y-0 w-20 cursor-pointer justify-center hover:bg-gray-50"
                  >
                    {year}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </PopoverContent>
        </Popover>
      </div>
      {numberOfMonths === 2 && (
        <div className="flex gap-2">
          <Select
            onValueChange={(value) => {
              handleMonthChange(months.indexOf(value), "to")
              setSelectedRange(null)
            }}
            value={
              internalDate.to ? months[internalDate.to.getMonth()] : undefined
            }
          >
            <SelectTrigger className="h-9 w-auto font-medium hover:bg-accent hover:text-accent-foreground focus:ring-0 focus:ring-offset-0">
              <SelectValue placeholder="Mês" />
            </SelectTrigger>
            <SelectContent>
              {months.map((month, idx) => (
                <SelectItem
                  disabled={
                    monthFrom !== undefined &&
                    yearTo !== undefined &&
                    yearFrom !== undefined &&
                    idx < monthFrom?.getMonth() &&
                    yearFrom >= yearTo
                  }
                  key={idx}
                  value={month}
                >
                  {month}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <Popover modal>
            <PopoverTrigger className="h-9 w-20">
              <Button
                type="button"
                variant="outline"
                className="w-20 justify-between"
              >
                <p>{yearTo || "Ano"}</p>
                <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-24 p-0">
              <Command value={yearTo ? yearTo.toString() : undefined}>
                <CommandInput placeholder={"Ano"} />
                <CommandGroup className="max-h-64 w-24 overflow-auto">
                  {years.map((year) => (
                    <CommandItem
                      key={year}
                      disabled={yearFrom != undefined && year < yearFrom}
                      value={year.toString()}
                      onSelect={(value) => {
                        handleYearChange(Number(value), "to")
                        setSelectedRange(null)
                      }}
                      className="inset-y-0 w-20 justify-center"
                    >
                      {year}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </Command>
            </PopoverContent>
          </Popover>
          <Button
            variant={"outline"}
            className="px-2"
            onClick={() => {
              if (internalDate.to) {
                setDisplayedMonth(subMonths(internalDate.to, 1))
              }
            }}
          >
            <ChevronsRightIcon
              strokeWidth={1.5}
              className="h-4 w-4 text-muted-foreground"
            />
          </Button>
        </div>
      )}
    </div>
  )
}
