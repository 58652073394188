import { COMPANY_PARAMETRIZATION } from "@/constants"
import { create } from "zustand"

interface ParameterizationProps {
  commission_type: string
  company: number
  cost_type: string
  curve_days: number
  delinquency_analysis_months: number
  free_analysis_days: number
  free_analysis_months: number
  free_qty_sales: number
  grace_period: number
  id: number
  margin_type: string
  medium_term_delinquency_days: number
  notifications_days_fired_users: number
  portfolio_end_user: boolean
  portifolio_analysis_days: number
  portifolio_analysis_months: number
  portifolio_free_days: number
  portifolio_inactivating_days: number
  portifolio_inactive_days: number
  portifolio_qty_sales: number
  saturday: number
  short_term_delinquency_days: number
  stock_analysis_days: number
  stock_buy_days: number
  stock_max_days: number
  stock_min_days: number
  stock_negative_values: boolean
  stock_turnover_days: number
  off_days_financial_situation: number
}

interface ParameterizationStore {
  companyParametrization: ParameterizationProps | null
  setCompanyParametrization: (
    companyParametrization: ParameterizationProps | null
  ) => void
}

export const useCompanyParameterizationStore = create<ParameterizationStore>(
  (set) => ({
    companyParametrization:
      typeof window !== "undefined" &&
      localStorage.getItem(COMPANY_PARAMETRIZATION)
        ? JSON.parse(localStorage.getItem(COMPANY_PARAMETRIZATION)!)
        : null,
    setCompanyParametrization: (companyParametrization) => {
      if (!companyParametrization?.company) {
        console.warn("Property 'company' is missing on parametrization object!")
        return
      }
      set({ companyParametrization })
      if (typeof window !== "undefined") {
        localStorage.setItem(
          COMPANY_PARAMETRIZATION,
          JSON.stringify(companyParametrization)
        )
      }
    }
  })
)
