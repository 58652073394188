import * as React from "react"
import { CalendarDaysIcon, ChevronDownIcon } from "lucide-react"
import {
  startOfMonth,
  endOfMonth,
  endOfDay,
  subMonths,
  isAfter,
  subDays,
  startOfDay
} from "date-fns"
import { toDate, formatInTimeZone } from "date-fns-tz"
import { DateRange } from "react-day-picker"

import { cn } from "@/lib/utils"
import { Button } from ".."
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger
} from "@/components/ui/popover"
import { ptBR } from "date-fns/locale"
import {
  CalendarDatePickerProps,
  months
} from "@/constants/components/calendar-date-picker"
import CalendarDatePickerPeriodSelector from "./Components/CalendarDatePickerPeriodSelector"
import CalendarDatePickerRangeSelector from "./Components/CalendarDatePickerRangeSelector"

const today = new Date()

export const CalendarDatePicker = React.forwardRef<
  HTMLButtonElement,
  CalendarDatePickerProps
>(
  (
    {
      id = "calendar-date-picker",
      className,
      date,
      closeOnSelect = false,
      isFormField = true,
      numberOfMonths = 2,
      yearsRange = 50,
      onDateSelect,
      variant = "outline",
      maxDate,
      allowPastDates = true,
      ...props
    },
    ref
  ) => {
    const [isPopoverOpen, setIsPopoverOpen] = React.useState(false)
    const [internalDate, setInternalDate] = React.useState<DateRange>({
      from: date?.from || startOfMonth(today),
      to: date?.to || endOfMonth(today)
    })
    const [selectedRange, setSelectedRange] = React.useState<string | null>(
      numberOfMonths === 2 ? "Este ano" : "Hoje"
    )
    const [monthFrom, setMonthFrom] = React.useState<Date | undefined>(
      date?.from
    )
    const [yearFrom, setYearFrom] = React.useState<number | undefined>(
      date?.from?.getFullYear()
    )
    const [monthTo, setMonthTo] = React.useState<Date | undefined>(
      numberOfMonths === 2 ? date?.to : date?.from
    )
    const [yearTo, setYearTo] = React.useState<number | undefined>(
      numberOfMonths === 2 ? date?.to?.getFullYear() : date?.from?.getFullYear()
    )
    const [displayedMonth, setDisplayedMonth] = React.useState<
      Date | undefined
    >(date?.from || startOfMonth(today))

    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const handleClose = () => setIsPopoverOpen(false)

    const maxDateVal = endOfDay(maxDate ? maxDate : "")
    const selectDateRange = (from: Date, to: Date, range: string) => {
      if (
        maxDate &&
        (isAfter(startOfDay(from), maxDateVal) ||
          isAfter(startOfDay(to), maxDateVal))
      ) {
        return
      }

      const startDate = startOfDay(toDate(from, { timeZone }))
      const endDate =
        numberOfMonths === 2 ? startOfDay(toDate(to, { timeZone })) : startDate

      if (
        maxDate &&
        (isAfter(startDate, maxDateVal) || isAfter(endDate, maxDateVal))
      ) {
        return
      }

      closeOnSelect && onDateSelect({ from: startDate, to: endDate })
      setInternalDate({ from: startDate, to: endDate })
      setSelectedRange(range)
      setMonthFrom(from)
      setYearFrom(from.getFullYear())
      setMonthTo(to)
      setYearTo(to.getFullYear())
      closeOnSelect && setIsPopoverOpen(false)
    }

    const years = Array.from(
      { length: yearsRange + 1 },
      (_, i) => today.getFullYear() - yearsRange / 2 + i
    )

    const handleDateSelect = (range: DateRange) => {
      if (!range || (!range.from && !range.to)) {
        if (date) {
          setInternalDate({ from: date?.from, to: date?.to })
        }
        return
      }

      let from = startOfDay(toDate(range.from as Date, { timeZone }))
      let to = range.to ? startOfDay(toDate(range.to, { timeZone })) : from

      if (maxDate && (isAfter(from, maxDateVal) || isAfter(to, maxDateVal))) {
        setInternalDate({
          from: date?.from || startOfMonth(maxDateVal),
          to: date?.to || maxDateVal
        })
        return
      }

      if (numberOfMonths === 1) {
        if (range.from !== internalDate.from) {
          to = from
        } else {
          from = toDate(range.to as Date, { timeZone })
        }
      }

      onDateSelect({ from, to })
      setInternalDate({ from, to })
      setMonthFrom(from)
      setYearFrom(from.getFullYear())
      setMonthTo(to)
      setYearTo(to.getFullYear())
      setSelectedRange(null)
    }

    const handleMonthChange = React.useCallback(
      (newMonthIndex: number, part: string) => {
        setSelectedRange(null)
        if (part === "from") {
          if (yearFrom !== undefined) {
            if (newMonthIndex < 0 || newMonthIndex > yearsRange + 1) return
            const newMonth = new Date(yearFrom, newMonthIndex, 1)
            const from =
              numberOfMonths === 2
                ? startOfMonth(toDate(newMonth, { timeZone }))
                : internalDate.from
                  ? new Date(
                      internalDate.from.getFullYear(),
                      newMonth.getMonth(),
                      internalDate.from.getDate()
                    )
                  : newMonth
            let to =
              numberOfMonths === 2
                ? internalDate.to
                  ? endOfDay(toDate(internalDate.to, { timeZone }))
                  : endOfMonth(toDate(newMonth, { timeZone }))
                : from

            if (from > to) {
              to = from
            }

            setInternalDate({ from, to })
            setMonthFrom(newMonth)
            setDisplayedMonth(newMonth)
            setMonthTo(internalDate.to)
          }
        } else {
          if (yearTo !== undefined) {
            if (newMonthIndex < 0 || newMonthIndex > yearsRange + 1) return
            const newMonth = new Date(yearTo, newMonthIndex, 1)
            const from = internalDate.from
              ? toDate(internalDate.from, { timeZone })
              : startOfMonth(toDate(newMonth, { timeZone }))
            const to =
              numberOfMonths === 2
                ? endOfMonth(toDate(newMonth, { timeZone }))
                : from
            if (from <= to) {
              setInternalDate({ from, to })
              setMonthTo(newMonth)
              setDisplayedMonth(subMonths(newMonth, 1))
              setMonthFrom(internalDate.from)
            }
          }
        }
      },
      [
        internalDate.from,
        internalDate.to,
        numberOfMonths,
        timeZone,
        yearFrom,
        yearTo,
        yearsRange
      ]
    )

    const handleYearChange = React.useCallback(
      (newYear: number, part: string) => {
        setSelectedRange(null)

        if (part === "from") {
          const newMonth = monthFrom
            ? new Date(newYear, monthFrom.getMonth(), 1)
            : new Date(newYear, 0, 1)
          const from =
            numberOfMonths === 2
              ? startOfMonth(toDate(newMonth, { timeZone }))
              : internalDate.from
                ? new Date(
                    newYear,
                    newMonth.getMonth(),
                    internalDate.from.getDate()
                  )
                : newMonth

          let to = internalDate.to
            ? toDate(internalDate.to, { timeZone })
            : endOfMonth(toDate(newMonth, { timeZone }))

          if (from > to) {
            to = from
          }

          setInternalDate({ from, to })
          setYearFrom(newYear)
          setMonthFrom(newMonth)
          setDisplayedMonth(newMonth)
          setYearTo(to.getFullYear())
          setMonthTo(to)
        } else {
          const newMonth = monthTo
            ? new Date(newYear, monthTo.getMonth(), 1)
            : new Date(newYear, 0, 1)

          const from = internalDate.from
            ? toDate(internalDate.from, { timeZone })
            : startOfMonth(toDate(newMonth, { timeZone }))

          const to =
            numberOfMonths === 2
              ? endOfMonth(toDate(newMonth, { timeZone }))
              : from

          setInternalDate({ from, to })
          setYearTo(newYear)
          setMonthTo(newMonth)
          setDisplayedMonth(subMonths(newMonth, 1))
          setYearFrom(internalDate.from?.getFullYear())
          setMonthFrom(internalDate.from)
        }
      },
      [
        internalDate.from,
        internalDate.to,
        monthFrom,
        monthTo,
        numberOfMonths,
        timeZone
      ]
    )

    const formatWithTz = (date: Date, fmt: string) =>
      formatInTimeZone(date, timeZone, fmt, { locale: ptBR })

    React.useEffect(() => {
      if (date) {
        setInternalDate({
          from: date?.from,
          to: date?.to
        })
      }
    }, [date])

    return (
      <>
        <Popover
          open={isPopoverOpen}
          onOpenChange={() => {
            setIsPopoverOpen(!isPopoverOpen)
            handleDateSelect(internalDate)
          }}
        >
          <PopoverTrigger
            asChild
            className={cn("h-11 w-full", !isFormField && "h-9")}
          >
            <Button
              id="date"
              ref={ref}
              {...props}
              className={cn(
                "w-full justify-between",
                className,
                !isFormField &&
                  "flex w-auto text-sm font-semibold text-zinc-600"
              )}
              variant={variant}
            >
              <div className="flex items-center">
                {!isFormField ? (
                  <CalendarDaysIcon className="mr-2 h-4 w-4" />
                ) : null}
                <span>
                  {internalDate?.from ? (
                    internalDate.to &&
                    formatWithTz(internalDate.from, "dd/MM/yyyy") !==
                      formatWithTz(internalDate.to, "dd/MM/yyyy") ? (
                      <>
                        <span id={`firstDay-${id}`}>
                          {formatWithTz(internalDate.from, "dd")}
                        </span>
                        {" de "}
                        <span id={`firstMonth-${id}`}>
                          {formatWithTz(
                            internalDate.from,
                            window.innerWidth > 420 ? "LLLL" : "LLL"
                          )}
                        </span>
                        {" de "}
                        <span id={`firstYear-${id}`}>
                          {formatWithTz(internalDate.from, "y")}
                        </span>
                        {numberOfMonths === 2 && (
                          <>
                            {" - "}
                            <span id={`secondDay-${id}`}>
                              {formatWithTz(internalDate.to, "dd")}
                            </span>
                            {" de "}
                            <span id={`secondMonth-${id}`}>
                              {formatWithTz(
                                internalDate.to,
                                window.innerWidth > 420 ? "LLLL" : "LLL"
                              )}
                            </span>
                            {" de "}
                            <span id={`secondYear-${id}`}>
                              {formatWithTz(internalDate.to, "y")}
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span id="day">
                          {formatWithTz(internalDate.from, "dd")}
                        </span>
                        {" de "}
                        <span id="month">
                          {formatWithTz(internalDate.from, "LLLL")}
                        </span>
                        {" de "}
                        <span id="year">
                          {formatWithTz(internalDate.from, "y")}
                        </span>
                      </>
                    )
                  ) : (
                    <span>Selecione uma data</span>
                  )}
                </span>
              </div>
              {isFormField ? (
                <ChevronDownIcon className="h-4 w-4 shrink-0 opacity-50" />
              ) : null}
            </Button>
          </PopoverTrigger>
          {isPopoverOpen && (
            <PopoverContent
              className="w-auto"
              onInteractOutside={handleClose}
              onEscapeKeyDown={handleClose}
              style={{
                maxHeight: "var(--radix-popover-content-available-height)",
                overflowY: "auto"
              }}
            >
              <div className="flex max-[420px]:flex-col">
                <CalendarDatePickerPeriodSelector
                  numberOfMonths={numberOfMonths}
                  selectDateRange={selectDateRange}
                  allowPastDates={allowPastDates}
                  maxDateVal={maxDateVal}
                  selectedRange={selectedRange}
                  setMonthFrom={setMonthFrom}
                  setDisplayedMonth={setDisplayedMonth}
                  setYearFrom={setYearFrom}
                  today={today}
                  setMonthTo={setMonthTo}
                  setYearTo={setYearTo}
                  maxDate={maxDate}
                />
                <div className="flex flex-col">
                  <CalendarDatePickerRangeSelector
                    setDisplayedMonth={setDisplayedMonth}
                    handleMonthChange={handleMonthChange}
                    setSelectedRange={setSelectedRange}
                    internalDate={internalDate}
                    months={months}
                    allowPastDates={allowPastDates}
                    today={today}
                    yearFrom={yearFrom}
                    years={years}
                    handleYearChange={handleYearChange}
                    numberOfMonths={numberOfMonths}
                    monthFrom={monthFrom}
                    yearTo={yearTo}
                  />

                  <div className="flex">
                    <Calendar
                      mode="range"
                      defaultMonth={monthFrom}
                      month={displayedMonth}
                      onMonthChange={setDisplayedMonth}
                      locale={ptBR}
                      selected={internalDate}
                      onSelect={(value) => {
                        setYearFrom(value?.from?.getFullYear())
                        setMonthFrom(value?.from)
                        setYearTo(value?.to?.getFullYear())
                        setMonthTo(value?.to)

                        if (selectedRange) setSelectedRange(null)
                        setInternalDate({ from: value?.from, to: value?.to })
                      }}
                      disabled={(date) =>
                        (allowPastDates ? false : date < subDays(today, 1)) ||
                        (maxDate ? date > maxDate : false)
                      }
                      numberOfMonths={numberOfMonths}
                      className={className}
                    />
                  </div>
                </div>
              </div>
              {!isFormField ? (
                <div className="flex justify-end">
                  <Button
                    variant={"primary"}
                    onClick={() => {
                      handleDateSelect(internalDate)
                      setIsPopoverOpen(!isPopoverOpen)
                    }}
                  >
                    Filtrar
                  </Button>
                </div>
              ) : null}
            </PopoverContent>
          )}
        </Popover>
      </>
    )
  }
)
