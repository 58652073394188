import { Button } from "@/components"
import { dateRanges } from "@/constants/components/calendar-date-picker"
import { cn } from "@/lib/utils"

export default function CalendarDatePickerPeriodSelector({
  numberOfMonths,
  allowPastDates,
  selectDateRange,
  maxDateVal,
  selectedRange,
  setMonthFrom,
  setDisplayedMonth,
  setYearFrom,
  today,
  setMonthTo,
  setYearTo,
  maxDate
}) {
  return (
    <>
      {numberOfMonths === 2 && (
        <div className="flex flex-col gap-1 border-foreground/10 pr-4 text-left max-[420px]:grid max-[420px]:grid-cols-2 max-[420px]:pb-4 min-[420px]:border-r">
          {dateRanges.map(({ label, start, end, pastDate }) => {
            return (
              <Button
                disabled={
                  (!allowPastDates && pastDate) || (start || end) >= maxDateVal
                }
                key={label}
                variant="ghost"
                size="sm"
                className={cn(
                  "justify-start hover:bg-brand-blue-500 hover:text-background",
                  selectedRange === label &&
                    "bg-brand-blue-500 text-background hover:bg-brand-blue-700 hover:text-background"
                )}
                onClick={() => {
                  selectDateRange(
                    !allowPastDates && start <= today ? today : start,
                    maxDate && end >= maxDate ? maxDate : end,
                    label
                  )
                  setMonthFrom(
                    !allowPastDates && start <= today ? today : start
                  )
                  setDisplayedMonth(
                    !allowPastDates && start <= today ? today : start
                  )
                  setYearFrom(
                    !allowPastDates && start <= today
                      ? today.getFullYear()
                      : start.getFullYear()
                  )
                  setMonthTo(end)
                  setYearTo(end.getFullYear())
                }}
              >
                {label}
              </Button>
            )
          })}
        </div>
      )}
    </>
  )
}
