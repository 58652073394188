import { useQueryListUserCompanies } from "@/service/companies"
import { useCompanyStore } from "@/store/company"
import { CompanyDialog } from "../CompanyDialog"
import { PageLoader } from "../PageLoader"
import { Header } from "./Header"
import { Navigation } from "./Navigation"
import { NavigationMobile } from "./NavigationMobile"

export const Navbar = () => {
  const { company: companySelected } = useCompanyStore((state) => state)

  const { data: userCompanies, isLoading: isLoadingUserCompanies } =
    useQueryListUserCompanies({})

  if (isLoadingUserCompanies) {
    return <PageLoader variant="full" />
  }

  if (!isLoadingUserCompanies && companySelected.id == 0 && userCompanies) {
    return <CompanyDialog companies={userCompanies} />
  }

  return (
    <>
      <Header />

      <div className="w-full max-lg:hidden lg:flex">
        <Navigation />
      </div>

      <div className="max-lg:flex lg:hidden">
        <NavigationMobile />
      </div>
    </>
  )
}
