import axios, { AxiosInstance } from "axios"
import { COMPANY_PARAMETRIZATION, SESSION_USER, USER_TOKEN } from "../constants"

type ApiNames = "admin" | "reports" | "pbi" | null

const getApiBaseUrl = (apiName) => {
  switch (apiName) {
    case "admin":
      return import.meta.env.VITE_API_HOST_ADMIN
    case "reports":
      return import.meta.env.VITE_API_HOST_REPORTS
    case "pbi":
      return import.meta.env.VITE_POWER_BI_HOST
    default:
      return import.meta.env.VITE_API_HOST
  }
}

export const getApi = async (
  apiName: ApiNames = null
): Promise<AxiosInstance> => {
  const token = localStorage.getItem(USER_TOKEN) || ""

  const api = axios.create({
    baseURL: getApiBaseUrl(apiName)
  })

  api.interceptors.request.use(
    (config: any) => {
      if (apiName !== "pbi") {
        if (token !== "") {
          config.headers.Authorization = "Bearer " + JSON.parse(token).access
        }

        if (config.url && config.url.endsWith("query_v2")) {
          const isProduction = import.meta.env.MODE === "production"

          if (isProduction) {
            if (config.data) {
              config.data = {
                ...config.data,
                logPlatform: "web"
              }
            } else {
              config.data = { logPlatform: "web" }
            }
          }
        }
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  api.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error?.response?.status === 401) {
        localStorage.removeItem(USER_TOKEN)
        localStorage.removeItem(SESSION_USER)
        localStorage.removeItem(COMPANY_PARAMETRIZATION)

        window.location.href = "/login"
      } else {
        return Promise.reject(error)
      }
    }
  )

  return api
}

export const getStreamApi = async (
  routePath,
  params,
  method = "POST",
  apiName = ""
) => {
  const token = localStorage.getItem(USER_TOKEN) || ""

  const baseURL = getApiBaseUrl(apiName)

  const fetchParams = {
    url: `${baseURL}/${routePath}`,
    config: {
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${JSON.parse(token).access}`
      },
      body: JSON.stringify(params)
    }
  }

  const response = await fetch(fetchParams.url, fetchParams.config)

  if (!response.body) {
    throw new Error("ReadableStream not supported")
  }

  const result: any[] = []
  let buffer: string = ""

  const writableStream = new WritableStream({
    write(chunk) {
      buffer += chunk
      const parts = buffer.split(",\n")
      buffer = parts.pop() || ""
      parts.forEach((part) => {
        try {
          const json = JSON.parse(part)
          result.push(json)
        } catch (e) {
          buffer += part
        }
      })
    },
    close() {
      if (buffer) {
        try {
          const json = JSON.parse(buffer)
          result.push(json)
        } catch (e) {
          console.error("Erro ao decodificar JSON no buffer final:", e)
        }
      }
    }
  })

  const textStream = response.body.pipeThrough(new TextDecoderStream("utf-8"))
  await textStream.pipeTo(writableStream)

  return { dados: result }
}
