import { CampaignNewRuleProps } from "@/types/administrativeArea/campaign-new-rule";
import { ChangeCompanyInfoProps } from "@/types/administrativeArea/change-company-info";
import { EditCampaignProps } from "@/types/administrativeArea/edit-campaign";
import { CampaignEditRuleProps } from "@/types/administrativeArea/edit-campaign-rule";
import { EditUserProps } from "@/types/administrativeArea/edit-user";
import { MarketClassificationProps } from "@/types/administrativeArea/market-classification";
import { NewCampaignMutateProps } from "@/types/administrativeArea/new-campaign";
import { NewUserProps } from "@/types/administrativeArea/new-user";
import { SelectedProducts } from "@/types/administrativeArea/product-selected";
import { useMutation, useQuery } from "react-query";
import { getApi } from ".";

import {
  UserAddFollowerProps,
  UserConnectOriginalIdProps,
  UserDeleteFollowerProps,
  UserDisconnectOriginalIdProps,
  UserGetFollowing,
  UserSalesPeopleProps,
} from "@/types/administrativeArea/administrative-area";
import { CommissionRuleEditProps } from "@/types/administrativeArea/commission-edit-rule";
import { CommissionRuleNewProps } from "@/types/administrativeArea/commission-new-rule";
import { MobileEditProps } from "@/types/administrativeArea/edit-mobile";
import { ParameterizationCarrierFluxProps } from "@/types/administrativeArea/parameterization-carrier-flux";
import { ParameterizationClientCategoryProps } from "@/types/administrativeArea/parameterization-client-category";
import { ParameterizationDevolutionProps } from "@/types/administrativeArea/parameterization-devolution";
import {
  ParameterizationNewHolidayProps,
  ParameterizationStateChangeHolidayProps,
} from "@/types/administrativeArea/parameterization-holiday";
import { ParameterizationPortfolioProps } from "@/types/administrativeArea/parameterization-portfolio";
import { ParameterizationSellerEditProps } from "@/types/administrativeArea/parameterization-seller";
import { ParameterizationSituationProps } from "@/types/administrativeArea/parameterization-situation";
import {
  ParameterizationPaymentTypesFlowProps,
  ParameterizationPaymentTypesProps,
} from "@/types/administrativeArea/payment-types";
import { CompanyProps } from "@/types/company";

export const useQueryGetCompanyInfo = (id: number, options: object) => {
  return useQuery<CompanyProps>(
    ["queryGetCompanyInfo", id],
    async () => {
      const api = await getApi();
      return await api.get(`/company/${id}`).then((response) => response.data);
    },
    options
  );
};
export const useQueryGetLogsInfo = (
  id: number,
  dtini: string,
  dtend: string,
  options: object
) => {
  return useQuery(
    ["queryGetLogsInfo", id, dtini, dtend],
    async () => {
      const api = await getApi();
      return await api
        .get(`/log/accesslog/${id}?dtini=${dtini}&dtend=${dtend}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetUserLogsInfo = (
  companyId: number,
  userId: number,
  dtend: string,
  dtini: string,
  options: object
) => {
  return useQuery(
    ["queryGetUserLogsInfo", companyId, dtend, dtini, userId],
    async () => {
      const api = await getApi("admin");
      return await api
        .post("/log/reportlog", {
          dtini,
          dtend,
          companyId,
          userId,
        })
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetUsers = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetUsers", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/users/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetUsersFlow = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetUsersFlow", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/users/staff`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetUserBuyers = (companyId, options) =>
  useQuery(
    ["useQueryGetUserBuyers", companyId],
    async () => {
      const api = await getApi();

      const params: {
        report: string;
        companyId: string;
      } = {
        report: "usuarios_compradores",
        companyId,
      };

      return api
        .post(`/mobile/report/query_v2`, params)
        .then((result) => result.data.dados);
    },
    options
  );

export const useQueryGetReports = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetReports", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/listreports/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetPrints = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetPrints", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/list-prints/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetReportGroups = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetReportGroups", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/user/reportgroups/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetCampaigns = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetCampaigns", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/campaigns/${companyId}`)
        .then((response) => response.data.data);
    },
    options
  );
};

export const useQueryGetGroups = (options: object) => {
  return useQuery(
    ["queryGetGroups"],
    async () => {
      const api = await getApi();
      return await api.get("groups").then((response) => response.data);
    },
    options
  );
};

export const useQueryGetUser = (userId: number, options: object) => {
  return useQuery(
    ["queryGetUser", userId],
    async () => {
      const api = await getApi();
      return await api.get(`user/${userId}`).then((response) => response.data);
    },
    options
  );
};

export const useMutationChangeCompanyInfo = (options: object) =>
  useMutation(
    async ({
      id,
      nome_fantasia,
      razao_social,
      cnpj,
      database,
    }: ChangeCompanyInfoProps) => {
      const api = await getApi();
      return api
        .put(`/company/${id}`, {
          nome_fantasia,
          razao_social,
          cnpj,
          database,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationSetUserState = (options: object) =>
  useMutation(async ({ userId }: { userId: number }) => {
    const api = await getApi();
    return api.delete(`/user/${userId}`).then((result) => result.data);
  }, options);

export const useMutationNewCampaign = (options: object) =>
  useMutation(
    async ({
      companyId,
      amount,
      crit_bonus,
      criterion,
      description,
      end_date,
      margin,
      name,
      profit,
      revenues,
      start_date,
      status,
    }: NewCampaignMutateProps) => {
      const api = await getApi();
      return api
        .post(`/campaign/${companyId}`, {
          amount,
          crit_bonus,
          criterion,
          description,
          end_date,
          margin,
          name,
          profit,
          revenues,
          start_date,
          status,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationEditCampaign = (options: object) =>
  useMutation(
    async ({
      campaignId,
      amount,
      crit_bonus,
      criterion,
      description,
      end_date,
      margin,
      name,
      profit,
      revenues,
      start_date,
      status,
    }: EditCampaignProps) => {
      const api = await getApi();
      return api
        .put(`/campaign/${campaignId}`, {
          amount,
          crit_bonus,
          criterion,
          description,
          end_date,
          margin,
          name,
          profit,
          revenues,
          start_date,
          status,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationDeleteCampaign = (options: object) =>
  useMutation(async (campaignId: number) => {
    const api = await getApi();
    return api.delete(`/campaign/${campaignId}`).then((result) => result.data);
  }, options);

export const useMutationSetNewUser = (options: object) =>
  useMutation(
    async ({
      company,
      email,
      first_name,
      group,
      last_name,
      reportGroups,
      reports,
      reportsapi,
      username,
    }: NewUserProps) => {
      const api = await getApi();
      return api
        .post("/user/", {
          company,
          email,
          first_name,
          group,
          last_name,
          reportGroups,
          reports,
          reportsapi,
          username,
          prints: [],
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationEditUser = (options: object) =>
  useMutation(
    async ({
      userId,
      company,
      email,
      first_name,
      group,
      last_name,
      reportGroups,
      reports,
      reportsapi,
      username,
    }: EditUserProps) => {
      const api = await getApi();
      return api
        .put(`/user/${userId}`, {
          company,
          email,
          first_name,
          group,
          last_name,
          reportGroups,
          reports,
          reportsapi,
          username,
          prints: [],
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationUserDisconnectOriginalId = (options: object) =>
  useMutation(async ({ userIdFlow }: UserDisconnectOriginalIdProps) => {
    const api = await getApi();
    return api
      .delete(`salespeople/${userIdFlow}`)
      .then((result) => result.data);
  }, options);

export const useMutationUserConnectOriginalId = (options: object) =>
  useMutation(
    async ({
      salesmanId,
      salesmanName,
      userId,
    }: UserConnectOriginalIdProps) => {
      const api = await getApi();
      return api
        .post("salespeople/", { salesmanId, salesmanName, userId })
        .then((result) => result.data);
    },
    options
  );

export const useMutationUserAddFollower = (options: object) =>
  useMutation(
    async ({ companyId, followerId, userId }: UserAddFollowerProps) => {
      const api = await getApi();
      return api
        .post("following", { companyId, followerId, userId })
        .then((result) => result.data);
    },
    options
  );

export const useMutationUserDeleteFollower = (options: object) =>
  useMutation(async ({ followerId }: UserDeleteFollowerProps) => {
    const api = await getApi();
    return api.delete(`following/${followerId}`).then((result) => result.data);
  }, options);

export const useQueryUserGetSalesPeople = (
  companyId: number,
  options: object
) => {
  return useQuery<UserSalesPeopleProps[]>(
    ["queryUserGetSalesPeople", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`salespeople/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryUserGetFollowing = (userId: number, options: object) => {
  return useQuery<UserGetFollowing[]>(
    ["queryUserGetFollowing", userId],
    async () => {
      const api = await getApi();
      return await api
        .get(`following/${userId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetCampaignCompanies = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCampaignCompanies", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`campaign/companies/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetCampaignSellers = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCampaignSellers", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`campaign/sellers/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetCampaignSupervisors = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCampaignSupervisors", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`campaign/supervisors/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetCampaignProducts = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCampaignProducts", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`campaign/products-list/${companyId}`)
        .then((response) => response.data.data);
    },
    options
  );
};

export const useQueryGetCampaignSelectedProducts = (
  campaignId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCampaignSelectedProducts", campaignId],
    async () => {
      const api = await getApi();
      return await api
        .get(`campaign/products/${campaignId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useMutationSelectCampaignProducts = (options: object) =>
  useMutation(
    async ({
      campaignId,
      selectedProducts,
    }: {
      campaignId: number;
      selectedProducts: SelectedProducts[];
    }) => {
      const api = await getApi();
      return api
        .post(`/campaign/products/${campaignId}`, selectedProducts)
        .then((result) => result.data);
    },
    options
  );

export const useMutationCampaignNewRule = (options: object) =>
  useMutation(
    async ({
      campaignId,
      bonus,
      bonus_min,
      company_id,
      company_name,
      seller_id,
      seller_name,
      supervisor_id,
      supervisor_name,
      target,
      target_min,
    }: CampaignNewRuleProps) => {
      const api = await getApi();
      return api
        .post(`/campaign/assign/${campaignId}`, {
          bonus,
          bonus_min,
          company_id,
          company_name,
          seller_id,
          seller_name,
          supervisor_id,
          supervisor_name,
          target,
          target_min,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationNewMarketClassification = (options: object) =>
  useMutation(
    async ({
      campaignId,
      marketClassification,
    }: {
      campaignId: number;
      marketClassification: MarketClassificationProps;
    }) => {
      const api = await getApi();
      return await api
        .post(
          `campaign/market-classification-assign/${campaignId}`,
          marketClassification
        )
        .then((result) => result.data);
    },
    options
  );
export const useMutationCampaignEditRule = (options: object) =>
  useMutation(
    async ({
      ruleId,
      bonus,
      bonus_min,
      company_id,
      company_name,
      seller_id,
      seller_name,
      supervisor_id,
      supervisor_name,
      target,
      target_min,
    }: CampaignEditRuleProps) => {
      const api = await getApi();
      return api
        .put(`/campaign/assign/${ruleId}`, {
          bonus,
          bonus_min,
          company_id,
          company_name,
          seller_id,
          seller_name,
          supervisor_id,
          supervisor_name,
          target,
          target_min,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationEditMarketClassification = (options: object) =>
  useMutation(
    async ({
      marketClassificationId,
      marketClassification,
    }: {
      marketClassificationId: number;
      marketClassification: MarketClassificationProps;
    }) => {
      const api = await getApi();
      return await api
        .put(
          `campaign/market-classification-assign/${marketClassificationId}`,
          marketClassification
        )
        .then((result) => result.data);
    },
    options
  );

export const useMutationCampaignDeleteRule = (options: object) =>
  useMutation(async (ruleId: number) => {
    const api = await getApi();
    return api
      .delete(`/campaign/assign/${ruleId}`)
      .then((result) => result.data);
  }, options);

export const useMutationCampaignDeleteMarketClassification = (
  options: object
) =>
  useMutation(async (marketClassificationId: number) => {
    const api = await getApi();
    return api
      .delete(`campaign/market-classification-assign/${marketClassificationId}`)
      .then((result) => result.data);
  }, options);

export const useQueryGetCommissionsList = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCommissionsList", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/commissions/list/${companyId}`)
        .then((response) => response.data.data);
    },
    options
  );
};

export const useMutationCommissionNew = (options: object) =>
  useMutation(
    async ({
      company,
      aliqnormal,
      aliqpromotion,
      description,
    }: {
      company: number;
      aliqnormal: number;
      aliqpromotion: number;
      description: string;
    }) => {
      const api = await getApi();
      return api
        .post("/commissions/", {
          company,
          aliqnormal,
          aliqpromotion,
          description,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationCommissionDelete = (options: object) =>
  useMutation(async ({ commissionId }: { commissionId: number }) => {
    const api = await getApi();
    return api
      .delete(`/commissions/${commissionId}`)
      .then((result) => result.data);
  }, options);

export const useMutationCommissionEdit = (options: object) =>
  useMutation(
    async ({
      commissionId,
      aliqnormal,
      aliqpromotion,
      description,
    }: {
      commissionId: number;
      aliqnormal: number;
      aliqpromotion: number;
      description: string;
    }) => {
      const api = await getApi();
      return api
        .put(`/commissions/${commissionId}`, {
          aliqnormal,
          aliqpromotion,
          description,
        })
        .then((result) => result.data);
    },
    options
  );
export const useMutationCommissionRuleNew = (options: object) =>
  useMutation(
    async ({
      commission,
      aliqnormal,
      aliqpromotion,
      critpess,
      critprod,
      discount,
      margin,
      time,
    }: CommissionRuleNewProps) => {
      const api = await getApi();
      return api
        .post("/commissions/rule/", {
          commission,
          aliqnormal,
          aliqpromotion,
          critpess,
          critprod,
          discount,
          margin,
          time,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationCommissionRuleEdit = (options: object) =>
  useMutation(
    async ({
      ruleId,
      aliqnormal,
      aliqpromotion,
      critpess,
      critprod,
      discount,
      margin,
      time,
    }: CommissionRuleEditProps) => {
      const api = await getApi();
      return api
        .put(`/commissions/rule/${ruleId}`, {
          aliqnormal,
          aliqpromotion,
          critpess,
          critprod,
          discount,
          margin,
          time,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationCommissionRuleDelete = (options: object) =>
  useMutation(async (ruleId: number) => {
    const api = await getApi();
    return api
      .delete(`/commissions/rule/${ruleId}`, {})
      .then((result) => result.data);
  }, options);

export const useQueryGetCommissionRuleSelects = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetCommissionsRuleSelects", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`commissions/rule/selects/${companyId}`)
        .then((result) => result.data);
    },
    options
  );
};

export const useQueryGetGoalsEstablishments = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetGoalsEstablishments", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`goals/establishments/${companyId}`)
        .then((result) => result.data);
    },
    options
  );
};

export const useQueryGetGoalsSupervisors = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetGoalsSupervisors", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`goals/supervisors/${companyId}`)
        .then((result) => result.data);
    },
    options
  );
};

export const useMutationGoalNew = (options: object) =>
  useMutation(
    async ({
      name,
      start_date,
      end_date,
      priority,
      seller_type,
      working_days,
      company,
      calendar,
    }: {
      name: string;
      start_date: string;
      end_date: string;
      priority: number;
      seller_type: number;
      working_days: string;
      company: number;
      calendar: [];
    }) => {
      const api = await getApi();
      return api
        .post(`goals/campaign/`, {
          name,
          start_date,
          end_date,
          priority,
          seller_type,
          working_days,
          company,
          calendar,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalEdit = (options: object) =>
  useMutation(
    async ({
      name,
      start_date,
      end_date,
      priority,
      seller_type,
      working_days,
      goalId,
      calendar,
    }: {
      name: string;
      start_date: string;
      end_date: string;
      priority: number;
      seller_type: number;
      working_days: string;
      goalId: number;
      calendar: [];
    }) => {
      const api = await getApi();
      return api
        .put(`goals/campaign/${goalId}`, {
          name,
          start_date,
          end_date,
          priority,
          seller_type,
          working_days,
          calendar,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalManagerNew = (options: object) =>
  useMutation(
    async ({
      campaign,
      portfolio_cover,
      consumer_sale,
      non_payment,
      establishment_id,
      devolution,
      discount,
      manager,
      margin,
      goal,
      average_ticket,
      description,
    }: {
      campaign: string;
      average_ticket: number;
      portfolio_cover: number;
      consumer_sale: number;
      non_payment: number;
      establishment_id: number;
      devolution: number;
      discount: number;
      manager: object;
      margin: number;
      goal: number;
      description: string;
    }) => {
      const api = await getApi();
      return api
        .post(`goals/campaign/manager/`, {
          campaign,
          portfolio_cover,
          consumer_sale,
          non_payment,
          establishment_id,
          devolution,
          discount,
          manager,
          margin,
          goal,
          average_ticket,
          description,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalManagerEdit = (options: object) =>
  useMutation(
    async ({
      id,
      portfolio_cover,
      consumer_sale,
      non_payment,
      establishment_id,
      devolution,
      discount,
      managerId,
      margin,
      goal,
      average_ticket,
      description,
    }: {
      id: number;
      average_ticket: number;
      portfolio_cover: number;
      consumer_sale: number;
      non_payment: number;
      establishment_id: number;
      devolution: number;
      discount: number;
      managerId: number;
      margin: number;
      goal: number;
      description: string;
    }) => {
      const api = await getApi();
      return api
        .put(`goals/campaign/manager/${id}`, {
          portfolio_cover,
          consumer_sale,
          non_payment,
          establishment_id,
          devolution,
          discount,
          managerId,
          margin,
          goal,
          average_ticket,
          description,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalDelete = (options: object) =>
  useMutation(async (goalId: number) => {
    const api = await getApi();
    return api.delete(`goals/campaign/${goalId}`).then((result) => result.data);
  }, options);
export const useMutationGoalManagerDelete = (options: object) =>
  useMutation(async (managerId: number) => {
    const api = await getApi();
    return api
      .delete(`goals/campaign/manager/${managerId}`)
      .then((result) => result.data);
  }, options);
export const useMutationGoalSellerDelete = (options: object) =>
  useMutation(async (sellerId: number) => {
    const api = await getApi();
    return api
      .delete(`goals/campaign/salesman/${sellerId}`)
      .then((result) => result.data);
  }, options);

export const useMutationGoalSellerNew = (options: object) =>
  useMutation(
    async ({
      campaign,
      portfolio_cover,
      consumer_sale,
      non_payment,
      establishment_id,
      devolution,
      discount,
      salesman,
      margin,
      goal,
      average_ticket,
      penalty,
      manager,
    }: {
      campaign: number;
      manager: string;
      average_ticket: number;
      portfolio_cover: number;
      consumer_sale: number;
      non_payment: number;
      establishment_id: number;
      devolution: number;
      discount: number;
      salesman: object;
      margin: number;
      penalty: number;
      goal: number;
    }) => {
      const api = await getApi();
      return api
        .post(`goals/campaign/salesman/`, {
          campaign,
          manager,
          portfolio_cover,
          consumer_sale,
          non_payment,
          establishment_id,
          devolution,
          discount,
          salesman,
          margin,
          goal,
          average_ticket,
          penalty,
        })
        .then((result) => result.data);
    },
    options
  );
export const useMutationGoalSellerEdit = (options: object) =>
  useMutation(
    async ({
      salesmanGoalId,
      portfolio_cover,
      consumer_sale,
      non_payment,
      establishment_id,
      devolution,
      discount,
      salesmanId,
      margin,
      goal,
      average_ticket,
      penalty,
    }: {
      salesmanGoalId: number;
      average_ticket: number;
      portfolio_cover: number;
      consumer_sale: number;
      non_payment: number;
      establishment_id: number;
      devolution: number;
      discount: number;
      salesmanId: object;
      margin: number;
      penalty: number;
      goal: number;
    }) => {
      const api = await getApi();
      return api
        .put(`goals/campaign/salesman/${salesmanGoalId}`, {
          portfolio_cover,
          consumer_sale,
          non_payment,
          establishment_id,
          devolution,
          discount,
          salesmanId,
          margin,
          goal,
          average_ticket,
          penalty,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalPercentageEdit = (options: object) =>
  useMutation(async ({ data, goalId }: { data: any; goalId: number }) => {
    const api = await getApi();
    return api
      .put(`goals/increasePercent/${goalId}`, {
        ...data,
      })
      .then((result) => result.data);
  }, options);

export const useQueryGetMobileInfo = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetMobileInfo", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/mobile/salesman/infolist/${companyId}`)

        .then((response) => response.data);
    },
    options
  );
};
export const useQueryGoalsDuplicate = (goalId: number, options: object) => {
  return useQuery(
    ["queryGetGoalsDuplicate", goalId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/goals/campaign/${goalId}`)
        .then((response) => response.data);
    },
    options
  );
};

export const useMutationMobileUpdateInfoList = (options: object) =>
  useMutation(async (updatedData: MobileEditProps[]) => {
    const api = await getApi();
    return await api
      .put(`/mobile/salesman/infolist`, updatedData)
      .then((response) => response.data);
  }, options);

export const useMutationSaveLabel = (options: object) => {
  return useMutation(async (value: any) => {
    const api = await getApi();
    return await api
      .put(`v2/mobile/saveLabel`, value)
      .then((response) => response.data);
  }, options);
};

export const useQueryGetMobileIndicators = (options: object) => {
  return useQuery(
    ["queryGetMobileIndicators"],
    async () => {
      const api = await getApi();

      return await api
        .get(`/indicator/indicators`)
        .then((response) => response.data);
    },
    options
  );
};

export const useMutationMobileEditIndicator = (options: object) =>
  useMutation(
    async ({
      indicatorId,
      color,
      indicator,
      description,
      group,
    }: {
      indicatorId: number;
      color: string;
      indicator: string;
      description: string;
      group: string;
    }) => {
      const api = await getApi();
      return await api
        .put(`/indicator/indicator/${indicatorId}`, {
          color,
          indicator,
          description,
          group,
        })
        .then((response) => response.data);
    },
    options
  );

export const useMutationMobileDeleteIndicator = (options: object) =>
  useMutation(async (IndicatorId: number) => {
    const api = await getApi();
    return api
      .delete(`/indicator/indicator/${IndicatorId}`)
      .then((result) => result.data);
  }, options);

export const useMutationMobileNewIndicator = (options: object) =>
  useMutation(
    async ({
      color,
      indicator,
      description,
      group,
    }: {
      color: string;
      indicator: string;
      description: string;
      group: string;
    }) => {
      const api = await getApi();
      return api
        .post(`/indicator/indicator`, { color, indicator, description, group })
        .then((result) => result.data);
    },
    options
  );
export const useMutationGoalDuplicate = (options: object) =>
  useMutation(
    async ({
      name,
      start_date,
      end_date,
      priority,
      seller_type,
      working_days,
      goalId,
      company,
    }: {
      name: string;
      start_date: string;
      end_date: string;
      priority: number;
      seller_type: number;
      working_days: number;
      goalId: number;
      calendar: [];
      company: number;
    }) => {
      const api = await getApi("admin");
      return api
        .post(`/goals/campaign/copy/${goalId}`, {
          company,
          name,
          start_date,
          end_date,
          priority,
          seller_type,
          working_days,
          calendar: [],
        })
        .then((result) => result.data);
    },
    options
  );

export const useQueryGetMobileGroups = (options: object) => {
  return useQuery(
    ["queryGetMobileGroups"],
    async () => {
      const api = await getApi();
      return await api.get(`/indicator/groups`).then((result) => result.data);
    },
    options
  );
};
export const useQueryGetGoalsScore = (companyId: number, options: object) => {
  return useQuery(
    ["queryGetGoalsPoints", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/commissions/points/${companyId}`)
        .then((response) => response.data);
    },
    options
  );
};
export const useMutationMobileGroupEdit = (options: object) =>
  useMutation(
    async ({
      groupId,
      name,
      icon,
    }: {
      groupId: number;
      name: string;
      icon: string;
    }) => {
      const api = await getApi();
      return await api
        .put(`/indicator/group/${groupId}`, { name, icon })
        .then((result) => result.data);
    },
    options
  );

export const useMutationMobileDeleteGroup = (options: object) =>
  useMutation(async (groupId: number) => {
    const api = await getApi();
    return api
      .delete(`/indicator/group/${groupId}`)
      .then((result) => result.data);
  }, options);

export const useMutationMobileNewGroup = (options: object) =>
  useMutation(async ({ name, icon }: { name: string; icon: string }) => {
    const api = await getApi();
    return api
      .post(`/indicator/group`, { name, icon })
      .then((result) => result.data);
  }, options);

export const useQueryGetParameterizationCarriers = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationCarriers", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/carriers/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};
export const useQueryGetParameterizationHolidays = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationHolidays", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`listholidays/${companyId}`)
        .then((result) => result.data);
    },
    options
  );
};

export const useQueryGetParameterizationValues = (
  companyId: number,
  options: object
) => {
  return useQuery<ParameterizationPortfolioProps>(
    ["queryGetParameterizationValues", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`goals/parameters/${companyId}`)
        .then((result) => result.data);
    },
    options
  );
};

export const useQueryGetParameterizationSituations = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationSituations", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/situations/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationCategories = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationCategories", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/categories/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationSellers = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationSellers", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/sellers/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationSupervisors = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationSupervisor", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/supervisor/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationCommissions = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationCommissions", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/commissions/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationClientCategories = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationClientCategories", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/client-categories/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationDevolution = (
  companyId: number,
  options: object
) => {
  return useQuery(
    ["queryGetParameterizationDevolution", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`parameterization/devolution/${companyId}`)
        .then((result) => result.data.data);
    },
    options
  );
};

export const useQueryGetParameterizationAnalytics = (
  companyId: number,
  BASE: string,
  report: string,
  ESTAB_NOT_IN: number[],
  options: object
) => {
  return useQuery(
    [
      "queryGetParameterizationAnalytics",
      companyId,
      BASE,
      report,
      ESTAB_NOT_IN,
    ],
    async () => {
      const api = await getApi();
      return await api
        .post("/mobile/report/query_v2", {
          companyId,
          BASE,
          report,
          ESTAB_NOT_IN: ESTAB_NOT_IN.join(","),
        })
        .then((response) => response.data.dados);
    },
    options
  );
};

export const useQueryGetParameterizationPaymentTypesFlow = (
  options: object
) => {
  return useQuery<ParameterizationPaymentTypesFlowProps[]>(
    ["queryGetParameterizationPaymentTypesFlow"],
    async () => {
      const api = await getApi();
      return await api
        .get("/parameterization/payment-types")
        .then((response) => response.data);
    },
    options
  );
};

export const useQueryGetParameterizationPaymentTypesSystem = (
  companyId: number,
  options: object
) => {
  return useQuery<ParameterizationPaymentTypesProps[]>(
    ["queryGetParameterizationPaymentTypesSystem", companyId],
    async () => {
      const api = await getApi();
      return await api
        .get(`/parameterization/payment-types/${companyId}`)
        .then((response) => response.data.dados);
    },
    options
  );
};

export const useMutationParameterizationCarrierFlux = (options: object) =>
  useMutation(
    async ({
      companyId,
      carrierFluxEdit,
    }: {
      companyId: number;
      carrierFluxEdit: ParameterizationCarrierFluxProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/carriers/${companyId}`, {
          ...carrierFluxEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationPaymentTypesChange = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      grupo_flow,
      skformapag,
    }: {
      companyId: number;
      grupo_flow: string | null;
      skformapag: number;
    }) => {
      const api = await getApi();
      return api
        .patch(`parameterization/payment-types/${companyId}`, {
          grupo_flow,
          skformapag,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationHoliday = (options: object) =>
  useMutation(
    async ({
      companyId,
      holidayParameterization,
    }: {
      companyId: number;
      holidayParameterization: ParameterizationNewHolidayProps;
    }) => {
      const api = await getApi();
      return api
        .post(`holidays`, {
          companyId,
          ...holidayParameterization,
          ibge: 0,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationSaturday = (options: object) =>
  useMutation(
    async ({
      companyId,
      saturday,
    }: {
      companyId: number;
      saturday: number;
    }) => {
      const api = await getApi();
      return api
        .put(`goals/parameters/${companyId}/saturday/`, {
          companyId,
          saturday,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationNotifications = (options: object) =>
  useMutation(
    async ({
      companyId,
      notifications_days_fired_users,
    }: {
      companyId: number;
      notifications_days_fired_users: number;
    }) => {
      const api = await getApi();
      return api
        .put(`goals/parameters/${companyId}/notifications/`, {
          companyId,
          notifications_days_fired_users,
        })
        .then((result) => result.data);
    },
    options
  );




  export const useMutationParameterizationOffDays = (options: object) =>
    useMutation(
      async ({
        companyId,
        off_days_financial_situation,
      }:{
        companyId,
        off_days_financial_situation : number
      }) =>{
        const api = await getApi();
        return api
        .put(`goals/parameters/${companyId}/financial/`, {
          companyId,
          off_days_financial_situation
        })
        .then((result) => result.data)
      },
      options
    )
      

export const useMutationParameterizationPortfolio = (options: object) =>
  useMutation(
    async ({
      companyId,
      portfolio,
    }: {
      companyId: number;
      portfolio: ParameterizationPortfolioProps;
    }) => {
      const api = await getApi();
      return api
        .put(`goals/parameters/${companyId}/portifolio/`, {
          companyId,
          ...portfolio,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationHolidayState = (options: object) =>
  useMutation(
    async ({ active, holidayId }: ParameterizationStateChangeHolidayProps) => {
      const api = await getApi();
      return api
        .put(`holidays`, {
          active,
          holidayId,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationSituations = (options: object) =>
  useMutation(
    async ({
      companyId,
      situationEdit,
    }: {
      companyId: number;
      situationEdit: ParameterizationSituationProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/situations/${companyId}`, {
          ...situationEdit,
        })
        .then((result) => result.data);
    },
    options
  );



export const useMutationParameterizationChangeClientCategory = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      categoryEdit,
    }: {
      companyId: number;
      categoryEdit: ParameterizationClientCategoryProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/client-categories/${companyId}`, {
          ...categoryEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationNewFlowCategory = (options: object) =>
  useMutation(
    async ({ companyId, name }: { companyId: number; name: string }) => {
      const api = await getApi();
      return api
        .post(`parameterization/categories/${companyId}`, {
          name,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationSellerSupervisorEdit = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      sellerSupervisorEdit,
    }: {
      companyId: number;
      sellerSupervisorEdit: ParameterizationSellerEditProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/supervisor/${companyId}`, {
          ...sellerSupervisorEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationSellerCommissionEdit = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      sellerCommissionEdit,
    }: {
      companyId: number;
      sellerCommissionEdit: ParameterizationSellerEditProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/sellers/${companyId}`, {
          ...sellerCommissionEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationSellerCommissionExtEdit = (
  options: object
) =>
  useMutation(
    async ({
      companyId,
      sellerCommissionExtEdit,
    }: {
      companyId: number;
      sellerCommissionExtEdit: ParameterizationSellerEditProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/commissions2/${companyId}`, {
          ...sellerCommissionExtEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationSellerTypeEdit = (options: object) =>
  useMutation(
    async ({
      companyId,
      sellerTypeEdit,
    }: {
      companyId: number;
      sellerTypeEdit: ParameterizationSellerEditProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/sellers-type/${companyId}`, {
          ...sellerTypeEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationDevolutionEdit = (options: object) =>
  useMutation(
    async ({
      companyId,
      devolutionEdit,
    }: {
      companyId: number;
      devolutionEdit: ParameterizationDevolutionProps;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/devolution/${companyId}`, {
          ...devolutionEdit,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationParameterizationAnalyticGroupEdit = (options: object) =>
  useMutation(
    async ({
      companyId,
      analitica,
      grupodreId,
      sinteticaId,
    }: {
      companyId: number;
      analitica: { IDANALITICA: string };
      grupodreId: string | null;
      sinteticaId: string | null;
    }) => {
      const api = await getApi();
      return api
        .put(`parameterization/analytic/${companyId}`, {
          analitica,
          grupodreId,
          sinteticaId,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalScoreIndicatorNew = (options: object) =>
  useMutation(
    async ({
      campaignId,

      indicator,
      target,
      value0,
      value1,
      value2,
      value3,
      value4,
      value5,
      value6,
      value7,
      value8,
      value9,
      value10,
    }: {
      campaignId: number;
      indicator: string;

      target: number;
      value0: number;
      value1: number;
      value2: number;
      value3: number;
      value4: number;
      value5: number;
      value6: number;
      value7: number;
      value8: number;
      value9: number;
      value10: number;
    }) => {
      const api = await getApi("admin");
      return api
        .post(`/commissions/points/`, {
          campaignId,
          indicator,
          target,
          value0,
          value1,
          value2,
          value3,
          value4,
          value5,
          value6,
          value7,
          value8,
          value9,
          value10,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalScoreIndicatorEdit = (options: object) =>
  useMutation(
    async ({
      campaign,
      indicator,
      id,
      target,
      value0,
      value1,
      value2,
      value3,
      value4,
      value5,
      value6,
      value7,
      value8,
      value9,
      value10,
    }: {
      campaign: any;
      indicator: string;
      id: number;
      target: number;
      value0: number;
      value1: number;
      value2: number;
      value3: number;
      value4: number;
      value5: number;
      value6: number;
      value7: number;
      value8: number;
      value9: number;
      value10: number;
    }) => {
      const api = await getApi("admin");
      return api
        .put(`/commissions/points/`, {
          campaign,
          indicator,
          id,
          target,
          value0,
          value1,
          value2,
          value3,
          value4,
          value5,
          value6,
          value7,
          value8,
          value9,
          value10,
        })
        .then((result) => result.data);
    },
    options
  );

export const useMutationGoalScoreIndicatorDelete = (options: object) =>
  useMutation(async (id: number) => {
    const api = await getApi("admin");
    return api
      .delete(`/commissions/points/${id}`)
      .then((result) => result.data);
  }, options);

export const useMutationGoalScoreDuplicate = (options: object) =>
  useMutation(async ({ source, to }: { source: number; to: number }) => {
    const api = await getApi("admin");
    return api
      .post(`/commissions/points/copy`, {
        source,
        to,
      })
      .then((result) => result.data);
  }, options);
