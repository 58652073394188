import { PB_TOKEN, PB_TOKEN2 } from "@/constants"
import { UserGroups } from "@/constants/user"
import useAuthentication from "@/hooks/useAuthentication"
import { useUserGroupCredentials } from "@/hooks/useUserGroupCredentials"
import Notifications from "@/routes/Notifications"
import { useQueryGetParameterizationValues } from "@/service/administrative-area"
import {
  useQueryGetSellerType,
  useQueryGetUserPermission,
  useQueryPowerBiToken,
  useQueryPowerBiToken2,
  useQueryUserEstabsNotIn,
  useQueryUserInformation
} from "@/service/auth"
import { useQueryGetCompaniesModules } from "@/service/companies"
import { useCompanyStore } from "@/store/company"
import { useEstabsNotInStore } from "@/store/estabsNotIn"
import { useCompanyParameterizationStore } from "@/store/parameterization"
import { useUserStore } from "@/store/user"
import { CompanyModules } from "@/types/company"
import { UserMobileAccess, UserProps } from "@/types/user"
import { useState } from "react"
import { Link } from "react-router-dom"
import {
  CompanyCombobox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  UserAvatar
} from ".."
import { HeaderComplementaryApps } from "./HeaderComplementaryApps"
import { useCheckinParametersStore } from "@/store/checkinParameters"
import { useQueryGetCheckinParameterizationValues } from "@/service/complementaryApps/checkin"

export const Header = () => {
  const { logout } = useAuthentication()
  const userSelected = useUserStore((state) => state.user)
  const setUserSelected = useUserStore((state) => state.setUser)
  const setEstabsNotIn = useEstabsNotInStore((store) => store.setEstabsNotIn)
  const { setCompanyParametrization } = useCompanyParameterizationStore(
    (state) => state
  )
  const { company: companySelected, setCompany } = useCompanyStore(
    (state) => state
  )
  const setCheckinParameters = useCheckinParametersStore(
    (state) => state.setCheckinParameters
  )

  const { sellerId } = useUserGroupCredentials(userSelected)

  const [isOpen, setIsOpen] = useState(false)

  const { data: dataUser } = useQueryUserInformation({
    onSuccess: (data: UserProps) => {
      setUserSelected({ ...userSelected, ...data })
    }
  })

  useQueryGetSellerType(companySelected.id, sellerId, {
    enabled: !!userSelected && companySelected.id !== 0,
    onSuccess: (data) => {
      if (userSelected && data?.length > 0) {
        setUserSelected({
          ...userSelected,
          sellerType: data[0]?.SELLER_TYPE ?? ""
        })
      }
    }
  })

  useQueryGetCompaniesModules(companySelected.id, {
    enabled: companySelected.id !== 0,
    onSuccess: (data: CompanyModules[]) => {
      setCompany({
        ...companySelected,
        modules: data
      })
    }
  })

  useQueryPowerBiToken({
    enabled: companySelected?.id !== 0,
    onSuccess: (pbToken: object) => {
      localStorage.setItem(PB_TOKEN, JSON.stringify(pbToken))
    }
  })

  useQueryPowerBiToken2({
    enabled: companySelected?.id !== 0,
    onSuccess: (pbToken2: object) => {
      localStorage.setItem(PB_TOKEN2, JSON.stringify(pbToken2))
    }
  })

  useQueryGetUserPermission(
    companySelected?.id,
    userSelected?.id,
    userSelected?.group,
    {
      enabled:
        !!companySelected?.id && !!userSelected?.id && !!userSelected?.group,
      onSuccess: (data: UserMobileAccess) => {
        if (userSelected) {
          setUserSelected({
            ...userSelected,
            permissions: data.access
          })
        }
      }
    }
  )

  useQueryUserEstabsNotIn(companySelected.id, dataUser, {
    onSuccess: (data: { SKEMPRESA: number; ESTAB_NOT_IN }) => {
      if (Array.isArray(data[0].ESTAB_NOT_IN)) {
        setEstabsNotIn({
          ESTAB_NOT_IN: [...data[0].ESTAB_NOT_IN],
          SKEMPRESA: data[0].SKEMPRESA
        })

        return
      }

      setEstabsNotIn({
        ESTAB_NOT_IN: [data[0].ESTAB_NOT_IN],
        SKEMPRESA: data[0].SKEMPRESA
      })
    }
  })

  useQueryGetParameterizationValues(companySelected.id, {
    onSuccess: (data) => {
      setCompanyParametrization(data)
    }
  })

  useQueryGetCheckinParameterizationValues(companySelected.id, {
    onSuccess: (data) => {
      setCheckinParameters(data)
    }
  })

  return (
    <div className="flex w-full items-center justify-center border-b border-zinc-200 bg-white">
      <div className="flex h-10 w-full max-w-[110rem] items-center justify-between px-10">
        <Link to="/">
          <div className="flex items-center">
            <img
              src={"/assets/flow/fa-logo-symbol.webp"}
              alt="Logo da Flow Analytics"
              width={25}
              height={25}
            />

            <span className="mx-2 text-sm font-semibold text-gray-600 max-md:hidden">
              <strong>flow</strong>analytics
            </span>
          </div>
        </Link>

        <div className="flex items-center gap-1">
          <CompanyCombobox />

          {userSelected && userSelected.group === UserGroups.admin && (
            <Notifications />
          )}

          <HeaderComplementaryApps />

          {userSelected && (
            <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
              <DropdownMenuTrigger>
                <UserAvatar userInformation={userSelected} />
              </DropdownMenuTrigger>

              <DropdownMenuContent>
                <DropdownMenuLabel>{`Olá ${userSelected?.first_name}`}</DropdownMenuLabel>
                <DropdownMenuSeparator />

                <DropdownMenuItem>
                  <Link
                    to={
                      userSelected.group === UserGroups.normal
                        ? "/administrative/parameterization/potencial"
                        : userSelected.group === UserGroups.admin
                          ? "/administrative/company"
                          : "/administrative/campaigns"
                    }
                    onClick={() => setIsOpen(false)}
                  >
                    Área administrativa
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuItem>
                  <Link to="/profile" onClick={() => setIsOpen(false)}>
                    Meu perfil
                  </Link>
                </DropdownMenuItem>

                <DropdownMenuSeparator />
                <DropdownMenuItem onClick={() => logout()}>
                  Sair
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          )}
        </div>
      </div>
    </div>
  )
}
