import React from "react";
import { Input, InputProps } from "..";

interface MaskedInputProps extends InputProps {
  isCurrency?: boolean;
  isInteger?: boolean;
  acceptNegative?: boolean;
}

const MaskedInput = React.forwardRef<HTMLInputElement, MaskedInputProps>(
  (
    { onChange, isCurrency, isInteger, acceptNegative, defaultValue, ...props },
    ref
  ) => {
    const formatter = new Intl.NumberFormat("pt-BR", {
      style: isCurrency ? "currency" : "decimal",
      currency: isCurrency ? "BRL" : undefined,
      minimumFractionDigits: isInteger ? 0 : 2,
      maximumFractionDigits: isInteger ? 0 : 2,
    });

    const parseValue = (value: string) => {
      let digits = value.replace(/[^\d-]/g, "");
      if (acceptNegative && digits.includes("-")) {
        digits = "-" + digits.replace(/-/g, "");
      }
      if (!digits) {
        return 0;
      }
      return isInteger ? parseInt(digits, 10) : parseFloat(digits) / 100;
    };

    const formatValue = (value: number) => {
      if (isNaN(value) || value.toString() === "-") {
        return "-";
      }
      return isInteger
        ? value === 0
          ? ""
          : value.toString()
        : formatter.format(value);
    };

    const initializeValue = (initial: any) => {
      if (initial === undefined || initial === null) {
        return "";
      }
      return isInteger ? initial.toString() : formatValue(initial);
    };

    const [value, setValue] = React.useState(() =>
      initializeValue(defaultValue)
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const formattedValue = event.target.value;
      let realValue = parseValue(formattedValue);

      if (acceptNegative && formattedValue === "-0,0") {
        realValue = 0;
      }
      setValue(formatValue(realValue));

      if (onChange) {
        onChange({
          ...event,
          target: {
            ...event.target,
            value: realValue as unknown as string,
          },
        });
      }
    };

    return (
      <Input
        ref={ref}
        {...props}
        type="text"
        onChange={handleChange}
        value={value}
      />
    );
  }
);

export default MaskedInput;
