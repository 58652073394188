import { useState, useRef, useEffect } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  Command,
  CommandEmpty,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/components";
import { emptyCompany, useCompanyStore } from "@/store/company";
import { CompanyProps } from "@/types/company";
import { useNavigate } from "react-router-dom";

interface CompanyDialogProps {
  companies: Array<CompanyProps>;
}

export const CompanyDialog: React.FC<CompanyDialogProps> = ({ companies }) => {
  const actionRef = useRef<HTMLButtonElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  const [selectedCompany, setSelectedCompany] =
    useState<CompanyProps>(emptyCompany);

  const { setCompany } = useCompanyStore((state) => state);

  const handleSelectCompany = () => {
    setCompany(selectedCompany);
    navigate("/");
  };

  useEffect(() => {
    setTimeout(() => {
      inputRef.current?.focus();
    }, 5);
  }, []);

  return (
    <AlertDialog open={true}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle className="text-zinc-600">
            Selecione sua empresa
          </AlertDialogTitle>

          <AlertDialogDescription>
            <Command>
              <CommandInput
                ref={inputRef}
                placeholder="Filtre aqui as suas empresas disponíveis"
              />
              <CommandList>
                <CommandEmpty>Nenhuma empresa encontrada</CommandEmpty>
                {companies.map((company: CompanyProps) => (
                  <CommandItem
                    key={company.id}
                    onSelect={() => {
                      setSelectedCompany(company);
                      actionRef.current?.click();
                    }}
                    onDoubleClick={handleSelectCompany}
                    className={
                      company.id === selectedCompany.id
                        ? "bg-brand-blue-main text-white aria-selected:bg-brand-blue-main aria-selected:text-white"
                        : "bg-none"
                    }
                  >
                    {company.nome_fantasia}
                  </CommandItem>
                ))}
              </CommandList>
            </Command>
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter>
          <AlertDialogAction
            ref={actionRef}
            disabled={selectedCompany.id === 0}
            className="bg-brand-blue-main"
            onClick={handleSelectCompany}
          >
            Selecionar
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
