export const USER_TOKEN = "USER_TOKEN"
export const SESSION_USER = "user"
export const SESSION_COMPANY = "company"
export const COMPANY_PARAMETRIZATION = "companyParametrization"
export const USER_IP = "ip"
export const PB_TOKEN = "pbToken"
export const PB_TOKEN2 = "pbToken2"
export const FLOW_ANALYTICS_DATABASE_ID = 34
export const AGRIVITTA_DATABASE_ID = 116
export const REDE_PRO_DATABASE_ID = 96
export const POSTOS_PANDA_DATABASE_ID = 105
export const FLOW_ANALYTICS_PHONE = "554123911173"
