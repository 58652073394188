import { RotateCw } from "lucide-react";
import { useEffect } from "react";

interface PageLoaderProps {
  variant?: "full" | "small";
}

export const PageLoaderWithBackdrop = () => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 backdrop-blur-sm">
      <RotateCw className="h-8 w-8 animate-spin text-white" />
    </div>
  );
};

export const PageLoader: React.FC<PageLoaderProps> = ({ variant }) => {
  if (variant === "full") {
    return <PageLoaderWithBackdrop />;
  }

  return (
    <div className="max-w-screen flex h-full max-h-screen min-h-[calc(100vh-200px)] w-screen items-center justify-center">
      <RotateCw className="mr-2 h-6 w-6 animate-spin" />
    </div>
  );
};
