import { Avatar, AvatarFallback } from "..";

interface UserAvatarProps {
  userInformation: {
    first_name: string;
    last_name: string;
  };
}

export const UserAvatar = ({ userInformation }: UserAvatarProps) => {
  return (
    <Avatar className="h-8 w-8">
      <AvatarFallback className="bg-brand-blue-main text-sm font-semibold text-white">
        {`${userInformation.first_name.substring(
          0,
          1
        )}${userInformation.last_name.substring(0, 1)}`}
      </AvatarFallback>
    </Avatar>
  );
};
