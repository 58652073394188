import { create } from "zustand"
import { ParametrizationCheckinParameters } from "@/types/complementaryApps/checkIn"

interface CheckinParametersStore {
  checkinParameters: ParametrizationCheckinParameters
  setCheckinParameters: (
    checkinParameters: ParametrizationCheckinParameters
  ) => void
}

export const useCheckinParametersStore = create<CheckinParametersStore>(
  (set) => ({
    checkinParameters: {} as ParametrizationCheckinParameters,
    setCheckinParameters: (checkinParameters) => set({ checkinParameters })
  })
)
