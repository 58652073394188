import * as React from "react"
import * as ReactDOM from "react-dom/client"

import "./index.css"
import App from "./App"
import { Providers } from "./providers"

import "ag-grid-enterprise/styles/ag-grid.css"
import "ag-grid-enterprise/styles/ag-theme-quartz.css"

import * as Sentry from "@sentry/react"

Sentry.init({
  dsn: "https://d6a0df067684ed0ea714c99b4712c278@o4508561370578944.ingest.us.sentry.io/4508561372938240",
  integrations: [],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", "flowanalytics"],
  enabled: process.env.NODE_ENV !== "development"
})

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <Providers>
      <App />
    </Providers>
  </React.StrictMode>
)
