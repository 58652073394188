import { CheckIcon } from "lucide-react";
import * as React from "react";

import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandSeparator,
} from "@/components/ui/command";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

export const MultiSelectCommand = ({
  filteredOptions,
  handleSearch,
  handleInputKeyDown,
  toggleAll,
  handleSelectAllCheckIcon,
  selectedOptions,
  toggleOption,
  handleClear,
  setIsPopoverOpen,
}) => {
  return (
    <Command shouldFilter={false}>
      <CommandInput
        placeholder="Buscar..."
        onValueChange={handleSearch}
        onKeyDown={handleInputKeyDown}
      />

      <CommandGroup className="h-auto max-h-96 w-full overflow-auto">
        <CommandEmpty>Nenhum resultado.</CommandEmpty>
        {filteredOptions.length > 0 ? (
          <CommandItem
            key="all"
            onSelect={toggleAll}
            className="cursor-pointer"
          >
            <div
              className={cn(
                "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                handleSelectAllCheckIcon()
                  ? "bg-primary text-primary-foreground"
                  : "opacity-50 [&_svg]:invisible"
              )}
            >
              <CheckIcon className="h-4 w-4" />
            </div>
            <span>Selecionar todos</span>
          </CommandItem>
        ) : (
          <CommandItem>Nenhum resultado</CommandItem>
        )}

        {filteredOptions.map((option) => {
          const isSelected = selectedOptions.find(
            (o) => o.value == option.value
          );
          return (
            <CommandItem
              onSelect={() => toggleOption(option)}
              className="w-full"
            >
              <div
                className={cn(
                  "mr-2 flex h-4 w-4 items-center justify-center rounded-sm border border-primary",
                  isSelected
                    ? "bg-primary text-primary-foreground"
                    : "opacity-50 [&_svg]:invisible"
                )}
              >
                <CheckIcon className="h-4 w-4" />
              </div>
              {option.icon
                ? React.createElement(
                    option?.icon as React.ComponentType<{
                      className?: string;
                    }>,
                    {
                      className: "mr-1 h-4 w-4 text-muted-foreground",
                    }
                  )
                : null}
              <span>{option.label}</span>
            </CommandItem>
          );
        })}
      </CommandGroup>
      <CommandSeparator />
      <CommandGroup>
        <div className="flex items-center justify-between">
          {selectedOptions.length > 0 && (
            <>
              <CommandItem
                onSelect={handleClear}
                className="flex-1 cursor-pointer justify-center font-normal"
              >
                Limpar
              </CommandItem>
              <Separator
                orientation="vertical"
                className="flex h-full min-h-6"
              />
            </>
          )}
          <CommandItem
            onSelect={() => setIsPopoverOpen(false)}
            className="max-w-full flex-1 cursor-pointer justify-center"
          >
            Fechar
          </CommandItem>
        </div>
      </CommandGroup>
    </Command>
  );
};
