import {
  addWeeks,
  endOfWeek,
  format,
  FormatOptions,
  parseISO,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { ptBR } from "date-fns/locale";

export const getDateWithTimezone = (date: Date | string = new Date()) => {
  const timeZone = "UTC";

  let zonedDate;

  if (typeof date === "string") {
    zonedDate = toZonedTime(parseISO(date), timeZone);
  } else if (date instanceof Date) {
    zonedDate = toZonedTime(date, timeZone);
  } else {
    throw new Error("Invalid date format");
  }

  return zonedDate;
};

export const getWeekRange = (weekNumber) => {
  const firstDayOfYear = startOfYear(getDateWithTimezone(new Date()));
  const startOfWeekDate = startOfWeek(
    addWeeks(firstDayOfYear, weekNumber - 1),
    { weekStartsOn: 0 }
  );
  const endOfWeekDate = endOfWeek(startOfWeekDate, { weekStartsOn: 0 });

  return {
    from: startOfWeekDate,
    to: endOfWeekDate,
  };
};

export const formatDate = (
  value: string | Date,
  formatStr: string = "dd/MM/yyyy",
  useTimeZone: boolean = true,
  options?: FormatOptions
): string => {
  if (!value) return "";

  if (useTimeZone) {
    value = getDateWithTimezone(value);
  }

  return format(value, formatStr, { locale: ptBR, ...options });
};
